import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { lighten } from "polished"

const StyledInfobox = styled.div`
  padding: 0 1rem;
  border-radius: 3px;
  font-size: 1.5rem;
  border: 1px solid ${lighten(0.7, "#00263f")};
  background: ${lighten(0.8, "#00263f")};
  display: inline-block;
`

function Infobox({ children, ...props }) {
  return <StyledInfobox {...props}>{children}</StyledInfobox>
}

export default Infobox

Infobox.defaultProps = {}
Infobox.propTypes = {
  children: PropTypes.node.isRequired,
}
