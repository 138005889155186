import React, { useRef, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link, navigate } from "gatsby"
import { toggleLoading, setAuthenticated } from "actions/site"
import Loading from "components/Loading"
import Button from "components/Button"
import { SiteContext } from "context/SiteContext"
import { UserProvider } from "app/context/UserContext"
import { useUser } from "hooks/useUser"
import { logout } from "services/auth"

const StyledLayout = styled.section`
  max-width: 120rem;
  padding: 4rem 2rem 4rem;
  margin: 0 auto 4rem;
  ${(props) => props.theme.media.tablet_landscape_up`
    display: grid;
    grid-template-areas: "nav"
                         "main";

  `}
`

const StyledNav = styled.nav`
  grid-area: nav;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin: 1em 0;
      position: relative;
      a {
        color: ${(props) => props.theme.colors.white};
        text-decoration: none;
        font-size: 2.4rem;
        font-family: ${(props) => props.theme.fontStacks.subheading};
        letter-spacing: 0.1rem;
        text-transform: uppercase;
      }
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    justify-self: center;

    ul {
      display: flex;
      align-items: center;

      li {
        margin: 0 0 0 4rem;
        a {
          color: ${props.theme.colors.primary};
          font-size: 2rem;
          letter-spacing: 0.1rem;
          border-bottom: 3px solid transparent;
          &.active {
            color: ${props.theme.colors.coral};
            border-bottom: 3px solid currentColor;
          }
        }
      }
    }
  `}
`

const StyledMain = styled.div`
  grid-area: main;
  position: relative;
  min-height: 20rem;
  width: 100%;
`

function LayoutInner({ title, children, ...props }) {
  const { dispatch } = useContext(SiteContext)

  useUser()

  async function onLogoutHandler() {
    dispatch(toggleLoading(true))
    try {
      const result = await logout()
      if (result.data.status === "OK") {
        dispatch(toggleLoading(false))
        dispatch(setAuthenticated(false))
        navigate("/app/login")
      }
      dispatch(toggleLoading(false))
    } catch (error) {
      dispatch(toggleLoading(false))
    }
  }

  return (
    <StyledLayout {...props}>
      <StyledNav>
        <ul>
          <li>
            <Link to="/app" activeClassName="active">
              Profil
            </Link>
          </li>
          <li>
            <Link
              to="/app/integrationer"
              activeClassName="active"
              partiallyActive
            >
              Integrationer
            </Link>
          </li>
          {/* <li>
            <Link
              to="/app/opsamlinger"
              activeClassName="active"
              partiallyActive
            >
              Opsamlinger
            </Link>
          </li>
          <li>
            <Link
              to="/app/partnerevents"
              activeClassName="active"
              partiallyActive
            >
              Events
            </Link>
          </li>
          <li>
            <Link
              to="/app/indstillinger"
              activeClassName="active"
              partiallyActive
            >
              Indstillinger
            </Link>
          </li> */}
          <li>
            <Button size="sm" invert onClick={onLogoutHandler}>
              Log ud
            </Button>
          </li>
        </ul>
      </StyledNav>
      <StyledMain>
        <Loading />
        {children}
      </StyledMain>
    </StyledLayout>
  )
}

function Layout({ children, ...props }) {
  return <LayoutInner {...props}>{children}</LayoutInner>
}

export default Layout

Layout.defaultProps = {}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
