import React from "react"
// import PropTypes from 'prop-types'
// import styled from 'styled-components'
import { graphql } from "gatsby"
import Layout from "components/Layout"
import SEO from "components/SEO"
import SliceZone from "components/SliceZone"
import PrivateComponent from "components/PrivateComponent"
import Login from "app/Login"
import Home from "app/Home"
import Collections from "app/Collections"
import Events from "app/Events"
import Integrations from "app/Integrations"
import Settings from "app/Settings"

function UserPage({
  data: {
    prismic: { user_page, site_settings },
  },
  ...props
}) {
  if (!user_page) return null
  const { title, body, ...meta } = user_page
  const { uid } = props.pageContext

  function renderAppComponent(title) {
    switch (uid) {
      case "login":
        return null
      case "opsamlinger":
        return (
          <PrivateComponent
            component={<Collections title={title} />}
            unauthorizedComponent={<Login />}
          />
        )
      case "integrationer":
        return (
          <PrivateComponent
            component={<Integrations title={title} />}
            unauthorizedComponent={<Login />}
          />
        )
      case "partnerevents":
        return (
          <PrivateComponent
            component={<Events title={title} />}
            unauthorizedComponent={<Login />}
          />
        )
      case "indstillinger":
        return (
          <PrivateComponent
            component={<Settings title={title} />}
            unauthorizedComponent={<Login />}
          />
        )
      default:
        return (
          <PrivateComponent
            component={<Home title={title} />}
            unauthorizedComponent={<Login />}
          />
        )
    }
  }

  return (
    <Layout site_settings={site_settings}>
      <SEO
        title={meta.meta_title}
        description={meta.meta_description}
        images={meta.meta_images}
      />
      {renderAppComponent(title)}
      <SliceZone slices={body} />
    </Layout>
  )
}

export default UserPage

UserPage.defaultProps = {}

UserPage.propTypes = {}

export const query = graphql`
  query Userpage($lang: String!, $uid: String!, $isProduction: Boolean!) {
    prismic {
      user_page(lang: $lang, uid: $uid) {
        meta_title
        meta_description
        meta_images {
          image
          imageSharp {
            publicURL
          }
        }
        title
        body {
          ... on PRISMIC_User_pageBodyTiles {
            type
            label
            fields {
              tile {
                ... on PRISMIC_Tile {
                  preheader
                  header
                  button_text
                  image
                  imageSharp @include(if: $isProduction) {
                    childImageSharp {
                      id
                      main: fixed(quality: 80, width: 1280) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                      mobile: fixed(quality: 80, width: 480) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                  }
                  summary
                  link {
                    ... on PRISMIC_Article {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                  }
                }
              }
              tile_theme
            }
          }
          ... on PRISMIC_User_pageBodyModule {
            type
            label
            primary {
              module {
                ... on PRISMIC_Instructions {
                  _meta {
                    type
                  }
                  steps {
                    after_icon
                    before_icon
                    step_icon
                  }
                }
              }
            }
          }
          ... on PRISMIC_User_pageBodyCountdown {
            type
            label
            primary {
              countdown {
                ... on PRISMIC_Countdown {
                  ...CountdownFragment
                }
              }
              display_form
              display_greeting
              display_content
            }
          }
          ... on PRISMIC_User_pageBodyLogin {
            type
          }
          ... on PRISMIC_User_pageBodyParagraph {
            type
            label
            primary {
              title
              subtitle
              content
            }
          }
          ... on PRISMIC_User_pageBodyBrand_elements {
            type
            fields {
              brand_element_group_item {
                ... on PRISMIC_Brand_element_group {
                  ...BrandElementsGroup
                }
              }
            }
          }
          ... on PRISMIC_User_pageBodyCta {
            type
            primary {
              cta_content
              cta_brand_element_position
              cta_brand_element {
                ... on PRISMIC_Brand_element_group {
                  ...BrandElementsGroup
                }
              }
            }
          }
        }
      }
      site_settings(lang: $lang, uid: "site-settings") {
        ...Footer
      }
    }
  }
`
