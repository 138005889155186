import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledSettings = styled.div``

function Settings({ children, ...props }) {
  return <StyledSettings {...props}>{children}</StyledSettings>
}

export default Settings

Settings.defaultProps = {}
Settings.propTypes = {
  children: PropTypes.node.isRequired,
}
