import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"
import { setAuthenticated } from "actions/site"
import { SiteContext } from "context/SiteContext"
import { default as AppLayout } from "app/Layout"
import { auth } from "services/auth"

function PrivateComponent({ component, location, ...rest }) {
  const {
    state: { authenticated },
    dispatch,
  } = useContext(SiteContext)

  async function onAttemptRenderPrivateComponent() {
    try {
      const response = await auth()
      if (response.data.status === "OK") {
        dispatch(setAuthenticated(true))
      } else {
        dispatch(setAuthenticated(false))
        if (typeof window !== `undefined`) navigate("/app/login")
      }
    } catch (e) {
      // cogoToast.error('Something went wrong during authentication')
    }
  }

  // TODO: Make this more efficient as it's causing double calls.
  useEffect(() => {
    // On the initial pass, check if authenticated is false.
    if (!authenticated) onAttemptRenderPrivateComponent()
  }, [authenticated])

  // If authenticated is "false", render null initially. Otherwise render private route.
  return authenticated ? (
    <AppLayout>
      <component.type {...component.props} {...rest} />
    </AppLayout>
  ) : null
}

export default PrivateComponent
