import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import Content from "components/Content"
import Infobox from "components/Infobox"
import Measurements from "components/Measurements"
import { UserContext } from "app/context/UserContext"

const StyledIntegrations = styled.div`
  .lead {
    font-size: 2rem;
    max-width: 60rem;
  }

  code {
    background: ${(props) => props.theme.colors.primary};
    border-radius: 3px;
    padding: 0.25em 1rem;
    color: ${(props) => props.theme.colors.white};
    &::selection {
      background: ${(props) => props.theme.colors.secondary};
    }
  }

  h2 {
    margin-top: 2em;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    max-width: 100rem;
    margin: 0 auto;
  `}
`

function Integrations({ title, children, ...props }) {
  const {
    state: { user },
  } = useContext(UserContext)

  if (user === null) return null

  return (
    <>
      <Helmet>
        <script
          defer
          src="https://cdn.danmarksopsamling.dk/js/collection-widget.js"
        ></script>
      </Helmet>
      <StyledIntegrations {...props}>
        <Content content={title} />
        <article className="lead">
          <p>
            Her kan du læse, hvordan I kan integrere Danmarks
            Opsamlings-widgetten på jeres eget website og lade jeres tal tælle
            med i den landsdækkende Danmarks Opsamling.
          </p>
        </article>
        <h2>Step 1: Før I går i gang</h2>
        <p>
          For at I kan integrere vores Danmarks Opsamlings-widget, skal I bruge
          en API-nøgle.
        </p>
        <p>
          I får to nøgler, som I kan bruge, og som begge er <em>helt</em> unikke
          for jeres partnerkonto. API-nøglerne er jeres garanti for, at de
          opsamlinger, der bliver registreret på jeres website, tæller med i
          jeres egen samlede opsamling og den store, nationale Danmarks
          Opsamling.
        </p>
        <p>
          Herunder finder du to nøgler:
          <p>
            En <strong>TEST</strong> API-nøgle, som I kan teste widgetten med
            samt en <strong>LIVE</strong> API-nøgle, som I kan bruge, når I vil
            live med widgetten.
          </p>{" "}
        </p>
        <h3>Jeres unikke API-nøgler:</h3>
        <p>
          <strong>TEST API-NØGLE</strong>
          <br />
          <code>{user.test_api_key}</code>
        </p>
        <p>
          <strong>LIVE API-NØGLE</strong>
          <br />
          <code>{user.api_key}</code>
        </p>
        <Infobox>
          <p>
            <strong>VIGTIGT!</strong>
            <br />
            Så snart I anvender LIVE API-nøglen, tæller alle registreringer med.
          </p>
        </Infobox>
        <h2>Step 2: Vælg en metode til implementering af widget</h2>
        <p>
          Nedenunder finder I to metoder, I kan anvende: Javascript-metoden og
          iframe-metoden.
          <p> Vi anbefaler at bruge Javascript-metoden.</p>
        </p>
        <h3>Javascript-metoden</h3>
        <p>
          Indsæt følgende kodestump på jeres website i jeres{" "}
          <code>{`<head></head>`}</code>-tag:{" "}
        </p>
        <p>
          <code>
            {`<script
          defer
          src="https://cdn.danmarksopsamling.dk/js/collection-widget.js"
        ></script>`}
          </code>
        </p>
        <p>
          Kopier og indsæt følgende kodestump det sted på siden, hvor widgetten
          skal vises:
        </p>
        <p>
          Kodestump til <strong>TEST</strong> API-nøglen
        </p>
        <p>
          <code>
            {`<div id="LTOWR-collection-widget" data-apiKey="${user.test_api_key}></div>`}
          </code>
        </p>
        <p>
          Kodestump til <strong>LIVE</strong> API-nøglen
        </p>
        <p>
          <code>
            {`<div id="LTOWR-collection-widget" data-apiKey="${user.api_key}></div>`}
          </code>
        </p>
        <h3>iframe-metoden</h3>
        <p>
          Indsæt følgende kodestump det sted på siden, hvor widgetten skal
          vises:
        </p>
        <p>
          Kodestump til <strong>TEST</strong> API-nøglen
        </p>
        <p>
          <code>{`<iframe
    src="https://cdn.danmarksopsamling.dk/iframe/collection-widget/?apiKey=${user.test_api_key}"
    frameborder="0"
    width="100%"
  >
  </iframe>`}</code>
        </p>
        <p>
          Kodestump til <strong>LIVE</strong> API-nøglen
        </p>
        <p>
          <code>{`<iframe
    src="https://cdn.danmarksopsamling.dk/iframe/collection-widget/?apiKey=${user.api_key}"
    frameborder="0"
    width="100%"
  >
  </iframe>`}</code>
        </p>
        <h2>Step 3: Sådan skal det se ud</h2>
        <p>
          Når I har valgt en metode og implementeret den på jeres website, skal
          widgetten se således ud:
        </p>
        <div
          key={`widget`}
          id="LTOWR-collection-widget"
          data-apiKey={`${user.test_api_key}`}
        ></div>
        <h2>Step 4: Begynd at indsamle affald</h2>
        <Measurements lang="da" />
      </StyledIntegrations>
    </>
  )
}

export default Integrations

Integrations.defaultProps = {}
Integrations.propTypes = {
  children: PropTypes.node,
}
