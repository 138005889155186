import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Icon } from "@mostlycode/ui"

const StyledMeasurements = styled.div`
  .item {
    display: flex;
    align-items: center;

    + .item {
      margin-top: 2rem;
    }

    svg {
      width: 8rem;
      height: 8rem;
    }
  }

  .example {
    margin-top: 2rem;
  }
`

const dict = {
  en: {
    shoppingBag: "1 shopping bag (ie. a net bag) =",
    garbageBag: "1 large bag (ie. a black garbage bag) =",
    kg3: " 3kg",
    kg10: " 10kg",
    example:
      "Example: If you collect 8 shopping bags and 4 large bags, this corresponds to: 64 kg",
  },
  da: {
    shoppingBag: "1 bærepose (eksempelvis en Netto-pose) =",
    garbageBag: "1 sæk (eksempelvis en gennemsigtig affaldssæk) =",
    kg3: " 3kg",
    kg10: " 10kg",
    example:
      "Eksempel: Hvis du indsamler 8 bæreposer og 4 affaldssække, svarer det til: 64 kg",
  },
}

function Measurements({
  lang = "da",
  includeExample = false,
  children,
  ...props
}) {
  return (
    <StyledMeasurements {...props}>
      <div className="item">
        <Icon icon="bag" size="auto" />{" "}
        <span>
          {`${dict[lang].shoppingBag}`}
          <strong>{` ${dict[lang].kg3}`}</strong>
        </span>
      </div>
      <div className="item">
        <Icon icon="sack" size="auto" />{" "}
        <span>
          {`${dict[lang].garbageBag}`}
          <strong>{` ${dict[lang].kg10}`}</strong>
        </span>
      </div>
      {includeExample && (
        <div className="example">{`${dict[lang].example}`}</div>
      )}
    </StyledMeasurements>
  )
}

export default Measurements

Measurements.defaultProps = {}
Measurements.propTypes = {
  children: PropTypes.node.isRequired,
}
