import { useContext, useEffect } from "react"
import { auth } from "services/auth"
import { UserContext } from "app/context/UserContext"
import { setUser } from "app/actions/user"

export function useUser() {
  const {
    state: { user },
    dispatch,
  } = useContext(UserContext)

  async function getUser() {
    try {
      const response = await auth()
      if (response.data.status === "OK") {
        dispatch(setUser(response.data.body))
      } else {
        dispatch(setUser({}))
      }
    } catch (e) {
      dispatch(setUser({}))
    }
  }

  useEffect(() => {
    if (user === null) getUser()
  }, [])

  return user
}
