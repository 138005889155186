import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledCollections = styled.div``

function Collections({ children, ...props }) {
  return <StyledCollections {...props}>{children}</StyledCollections>
}

export default Collections

Collections.defaultProps = {}
Collections.propTypes = {
  children: PropTypes.node.isRequired,
}
