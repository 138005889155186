import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import { UserContext } from "app/context/UserContext"

const StyledHome = styled.div`
  text-align: center;
`

function Home({ children, ...props }) {
  const {
    state: { user },
  } = useContext(UserContext)
  if (!user) return null
  return (
    <StyledHome>
      <h2>Hej {user.name}</h2>
      {user.amount_collected > 0 ? (
        <p>Du har indtil videre opsamlet {user.amount_collected} kg affald.</p>
      ) : (
        <p>
          Du har endnu ikke samlet noget affald. Klik ind under{" "}
          <Link to="/app/integrationer">integrationer</Link> og se hvordan du
          kommer i gang.
        </p>
      )}
    </StyledHome>
  )
}

export default Home

Home.defaultProps = {}
Home.propTypes = {
  children: PropTypes.node,
}
