import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledTeams = styled.div``

function Events({ children, ...props }) {
  return <StyledTeams {...props}>{children}</StyledTeams>
}

export default Events

Events.defaultProps = {}
Events.propTypes = {
  children: PropTypes.node.isRequired,
}
